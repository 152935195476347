@use './index' as util;

@include util.spacing; // Include all spacings including margins, paddings and gaps.
@include util.heights;
@include util.self_align;
@include util.layouts;
@include util.font_sizes;
@include util.font_weights;
@include util.flex_order;

.fx-ellipsis {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.fx-fill {
	height: 100%;
	width: 100%;
}

.fx-w-fit {
	width: fit-content;
}

.fx-h-fit {
	height: fit-content;
}

.fx-bg-transparent {
	background-color: transparent;
}
.relative {
	position: relative;
}

.absolute {
	position: absolute;
}
.fixed {
	position: fixed;
}

.fx-h-fill {
	height: 100vh;
}

.fx-w-fill {
	width: 100vw;
}

.fx-fill-width {
	width: 100%;
}

.fx-fill-height {
	height: 100%;
}

.fx-grow-1 {
	flex-grow: 1;
}

.fx-overflow-hidden {
	overflow: hidden;
}

.fx-overflow-auto {
	overflow: auto;
}

.fx-layout-row {
	display: flex;
	flex-flow: row wrap;
}

.fx-layout-row-nowrap {
	display: flex;
	flex-flow: row nowrap;
}

.fx-layout-column {
	display: flex;
	flex-direction: column;
}

.fx-layout-column-nowrap {
	display: flex;
	flex-flow: column nowrap;
}

.fx-content-start {
	justify-content: flex-start;
}

.fx-content-center {
	justify-content: center;
}

.fx-content-end {
	justify-content: flex-end;
}

.fx-content-space-between {
	justify-content: space-between;
}

.fx-content-space-around {
	justify-content: space-around;
}

.fx-content-space-evenly {
	justify-content: space-evenly;
}

.fx-items-start {
	align-items: flex-start;
}
.fx-items-end {
	align-items: flex-end;
}
.fx-items-center {
	align-items: center;
}
.fx-items-stretch {
	align-items: stretch;
}
