.mat-mdc-option {
	min-height: 32px !important;

	.mdc-list-item__primary-text {
		width: 100%;
	}

	.select-badge {
		min-width: 46px;
		background-color: rgb(255, 179, 100);
		text-align: center;
		padding: 4px 0;
		border-radius: 12px;
		font-weight: 500;
	}
}
