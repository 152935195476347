@use './index' as bp;

.fx-hide-lt-sm {
	@include bp.fx-hide('lt-sm');
}

.fx-hide-lt-md {
	@include bp.fx-hide('lt-md');
}

.fx-hide-gt-xs {
	@include bp.fx-hide('gt-xs');
}

.fx-hide-sm {
	@include bp.fx-hide('sm');
}

.fx-hide-xs {
	@include bp.fx-hide('xs');
}

.fx-show-gt-xs {
	@include bp.fx-show('gt-xs');
}

.fx-show-lt-sm {
	@include bp.fx-show('lt-sm');
}
