$ft-border-radius: 12px;
$ft-toolbar-bg-color: #484867;

.fx-card {
	border-radius: $ft-border-radius !important;
	overflow: hidden;
}

.fx-search-area {

	h5 {
		font-size: var(--mat-form-field-container-text-size);
		font-weight: 600;
		color: var(--mat-form-field-enabled-select-arrow-color);
	}

	.ft-labels-filter {
		scale: 0.8;
		transform-origin: left top;
		position: relative;
		top: -4px;

		.ft-mdc-chip {
			font-weight: bold;
			font-size: 15px;
		}

		.ft-chip-input {
			scale: 1.2;
			transform-origin: left;
		}
	}
}

.fx-view-control {
	display: flex;
	justify-content: flex-start;
	flex-flow: row nowrap;
	align-items: center;
	gap: 2px;
}

.fx-paginator {
	border-radius: 0 0 $ft-border-radius $ft-border-radius;
}
