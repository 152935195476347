@use "styles/theme";

@use "./styles/components/icons";

@use "./styles/utils/layout";
@use "./styles/components/dialog";
@use "./styles/components/view-card";
@use "./styles/components/table";
@use "./styles/components/select-option";

@use "./styles/breakpoints/breakpoints";

/* @use 'quill/dist/quill.core.css'; */
@use "quill/dist/quill.snow.css";
@use "flexmonster/flexmonster.min.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    margin: 0;
    height: 100%;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

*::-webkit-scrollbar-thumb {
    background: rgba(208, 208, 208, 0.45);
    border-radius: 4px;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    display: none;
}

::-webkit-scrollbar-button {
    display: none;
}

::-webkit-scrollbar-track-piece {
    display: none;
}

::-webkit-scrollbar-corner {
    display: none;
}

::-webkit-resizer {
    display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.example-loading-shade {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 56px;
    right: 0;
    background: transparent;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.example-rate-limit-reached {
    color: #980000;
    max-width: 360px;
    text-align: center;
}

.spinner-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
}

.circle-border {
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border-right: 2px solid #6bb0ff;
    animation: spin 0.75s linear infinite;
}

.loader-text {
    position: absolute;
    font-weight: bold;
    font-size: 14px;
    font-family: sans-serif;
}

.loader-text img {
    height: 28px;
    //filter: hue-rotate(200deg);
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
